import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import compose from "ramda/src/compose";
import setPropTypes from "recompose/setPropTypes";

import Layout from "../layouts";

const licenses = [
  {
    name: `My Logo`,
    link: `https://www.instagram.com/p/BcGg8dTnsZ5/?taken-by=sahara.martin`,
    author: {
      name: `Sahara Martin`,
      link: `https://twitter.com/SaharaAMartin`
    },
    message: `is used with the artists permission! Check her out!`
  },
  {
    name: `Gatsby.js`,
    link: `https://www.gatsbyjs.org/`,
    author: {
      name: `over 1000 people!`,
      link: `https://github.com/gatsbyjs/gatsby/graphs/contributors`
    },
    license: {
      name: `MIT`,
      link: `https://tldrlegal.com/license/mit-license`
    }
  },
  {
    name: `Font Awesome Icons`,
    link: `https://fontawesome.com/`,
    author: {
      name: `Font Awesome`,
      link: `https://fontawesome.com/`
    },
    license: {
      name: `CC BY 4.0`,
      link: `https://creativecommons.org/licenses/by/4.0/`
    }
  }
];

const thankYouText = `Ultimately, this website was made by me and for me, but I
                      had a lot of help along the way. The following attributions
                      are here to respect copyright and give credit to the authors
                      of the amazing tools and resources that I've used.`;

const enhanceResourceAttribution = compose(
  setPropTypes({
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }).isRequired,
    license: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }),
    message: PropTypes.string
  })
);
const ResourceAttribution = enhanceResourceAttribution(
  ({ link = `#`, name, author, license = {}, message }) => (
    <li>
      <a href={link} target="__blank" rel="nofollow">
        {name}
      </a>
      {` `}
      by
      {` `}
      <a rel="nofollow" target="__blank" href={author.link || `#`}>
        {author.name || `unnamed`}
      </a>
      {` `}
      {license.name ? (
        <span>
          is licensed under
          {` `}
          <a rel="nofollow" target="__blank" href={license.link || `#`}>
            {license.name || `unlicensed`}
          </a>
        </span>
      ) : (
        <span>{message || `is unlicensed but much appreciated!`}</span>
      )}
    </li>
  )
);

const ThanksPage = () => (
  <Layout>
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section>
        <h2>Thanks are due!</h2>
        <hr />
        <p>{thankYouText}</p>
        <p>
          <ul>
            {licenses.map(resource => (
              <ResourceAttribution {...resource} />
            ))}
          </ul>
        </p>
      </section>
    </div>
  </Layout>
);

export default ThanksPage;
